/* You can add global styles to this file, and also import other style files */
.underline_link{
    color: #0F8BFD !important;
    text-decoration: underline;
}

.p-calendar {
    position: relative;
    display: inline-flex;
    width: 100% !important;
}

.p-dropdown
 {
    width: 100% !important;
    height: 35px;
    padding: 0px;
}

.disabled-menu-item{
  cursor: not-allowed !important;
  color: #98999F !important;
}
.p-dropdown .p-dropdown-trigger
{
    background-color: #696772 !important;
}

.header_title
{
    font-size: 25px;
    margin-left: 10px;
    font-weight: bold;
}

.header_subtitle{
  margin-top: 20px;
  font-size: 15px;
  font-weight: bold;
  max-width: 700px;
  width: 90%;
  text-align: center;
}

.content_text{
  font-size: 15px;
  max-width: 700px;
  text-align: justify;
}

.footer_subtitle{
  margin-top: 20px;
  font-size: 15px;
  max-width: 700px;
  width: 90%;
  text-align: center;
}

.float_div{
  max-width: 700px;
  width: 90%;
  height:100%;
  background-color:#494955;
  border-radius: 5px;
  margin-top: 25px;
}

input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }

.breadcrumb-title .p-menuitem-text {
  font-size: 22px;
  font-weight: bold !important;
}

.dropdown-style .p-placeholder {
  color: #dcdcdc !important;
}
